import { Form } from "react-bootstrap";
import banner from "../ReportStatus/ReportStatus.png";
import { useTranslation } from "react-i18next";
import {
  BsCircleFill,
  BsClock,
  BsFillExclamationCircleFill,
} from "react-icons/bs";

import { useContext, useEffect, useRef, useState } from "react";
import FormDataContext from "../../FormDataContext";
import VoiceRecorder from "../../components/VoiceRecorder";
import { CgAttachment } from "react-icons/cg";
import { Link } from "react-router-dom";
import axios from "axios";

const CaseDetails = () => {
  const [message, setMessage] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [caseDetails, setCaseDetails] = useState({});
  const [audioUrl, setAudioUrl] = useState("");
  const inputRef = useRef();

  const { formData, updateFormData, userReportDetails, uniqueNumber } =
    useContext(FormDataContext);
  const { t } = useTranslation();

  const handleUpload = (e) => {
    e.preventDefault();
    inputRef.current?.click();
  };
  const reportDetails = JSON.parse(localStorage.getItem("userReportDetails"));

  const handleTextareaChange = (event) => {
    setMessage(event.target.value);
    updateFormData({ message: event.target.value });
  };
  const handleDisplayFileDetails = () => {
    if (inputRef?.current?.files) {
      const file = inputRef.current.files[0];
      setUploadedFileName(file?.name);
      console.log("FILE", file);
      updateFormData({ file });
    }
  };
  const textareaStyle = {
    resize: "none",
  };

  const updateCaseComment = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "updateCaseComment");
    formDataToSend.append("case_code", uniqueNumber);
    formDataToSend.append("file", uploadedFileName);
    formDataToSend.append("record", audioUrl);
    formDataToSend.append("message", message);
    try {
      const res = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );
      console.log(res, "close");
    } catch (error) {
      console.log(error.message);
    }
  };
  function handleSubmit(e) {
    e.preventDefault();
    console.log(formData);
    updateCaseComment();
  }

  const handleRecordingComplete = (url) => {
    setAudioUrl(url);
  };

  const getCase = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "getCaseByCode");
    formDataToSend.append("case_code", uniqueNumber);
    try {
      const res = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );
      setCaseDetails(res?.data.case_details);
      console.log(res?.data.case_details, "details");
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCase();
    console.log(caseDetails);
  }, []);

  return (
    <section className="container">
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12 col-md-9 d-flex flex-column justify-content-center text-center  order-1 order-md-0 ">
          <h2
            className="fs-1 fw-bold secondary-color"
            style={{ fontWeight: "bold !important" }}
          >
            {t("repStatus")}
          </h2>
          <div className="warning-div rounded-2 m-2 m-md-5 mt-md-2 p-2 mb-md-2 position-relative ">
            <div className=" d-flex align-items-center justify-content-between  ms-3 ms-md-5 gap-3 mb-2">
              <div>
                <span>
                  <BsClock size={35} />
                </span>
                <span className="primary-color ms-3">
                  {userReportDetails?.creattion_time}
                </span>
              </div>
              <div className="">
                <Link
                  href="/case-details"
                  className="text-decoration-none primary-color"
                >
                  Ticket No. {uniqueNumber}
                </Link>
              </div>
            </div>
            <div className="d-flex align-items-center  ms-3 ms-md-5 gap-3 mb-2">
              <span>
                <BsCircleFill size={35} />
              </span>
              {reportDetails?.status === "new" && (
                <span className="fs-3">{t("noReply")}</span>
              )}
              {reportDetails?.status === "in-progress" && (
                <span className="fs-3">{t("checkingRep")}</span>
              )}
              {reportDetails?.status === "closed" && (
                <span className="fs-3">{t("finished&Replied")}</span>
              )}
            </div>
            <div className="d-flex align-items-center  ms-3 ms-md-5 gap-3 mb-2">
              <span>
                <BsFillExclamationCircleFill size={35} fill="#00B0C9" />
              </span>
              <span className="primary-color">Update:{caseDetails?.reply}</span>
            </div>
          </div>
          {reportDetails?.reply_message && (
            <Form
              onSubmit={handleSubmit}
              className="m-2 m-md-5 mb-md-0 mt-md-2 p-2 px-0"
            >
              <Form.Group controlId="textarea">
                <Form.Control
                  as="textarea"
                  className="textare p-3 "
                  rows={1}
                  style={textareaStyle}
                  placeholder={t("addComment")}
                  onChange={handleTextareaChange}
                  value={message}
                  defaultValue={formData.message || ""}
                />
              </Form.Group>
              <div className="mt-3  px-1 mb-2">
                <div className="d-flex flow-row justify-content-end align-items-center">
                  <span>
                    <VoiceRecorder
                      onRecordingComplete={handleRecordingComplete}
                    />
                  </span>
                  <span>
                    <input
                      type="file"
                      ref={inputRef}
                      className="d-none"
                      onChange={handleDisplayFileDetails}
                    />
                    <button className="btn" onClick={handleUpload}>
                      <span>{uploadedFileName}</span>
                      <span>
                        <CgAttachment
                          cursor="pointer"
                          size={30}
                          className="chat-icon"
                        />
                      </span>
                    </button>
                  </span>
                  <span>
                    <button className="text-decoration-none border-0">
                      <span className="button border-0 text-white submit-btn rounded-1 fs-5 py-2 px-4">
                        {t("submit")}
                      </span>
                    </button>
                  </span>
                </div>
              </div>
            </Form>
          )}
          <div className="text-start  m-2 m-md-5 mt-md-0   mb-md-2">
            <span style={{ fontSize: "17px" }}>{t("attachTypes")}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseDetails;
