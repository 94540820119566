import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const authStatus = Cookies.get("isAuthorized");
    setIsAuthorized(authStatus === "true");
  }, []);

  const login = () => {
    setIsAuthorized(true);
    Cookies.set("isAuthorized", "true", { expires: 1 });
  };

  const logout = () => {
    setIsAuthorized(false);
    Cookies.remove("isAuthorized");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthorized, login, logout, setIsAuthorized }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
