import { NavLink, useLocation } from "react-router-dom";
import { customerContext } from "../context";
import logo from "./assets/logo.png";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { useContext } from "react";

const Navbar = () => {
  const location = useLocation();
  if (location.pathname === "/") {
  }
  const { changeLanguage } = useContext(customerContext);
  const { t } = useTranslation();
  return (
    <nav className="d-flex justify-content-between align-items-center p-3 p-md-5 ">
      <div>
        <NavLink to="/">
          <img src={logo} width={165} height={78} alt="Speak UP logo" />
        </NavLink>
      </div>
      <div>
        <ul className="list-unstyled d-flex gap-3 align-items-center">
          {location.pathname !== "/" && (
            <li style={{ color: "var(--main-color)" }}>
              {localStorage.getItem("i18nextLng") === "ar" ? (
                <button onClick={() => changeLanguage("en")} className="btn ">
                  English
                </button>
              ) : (
                <button
                  onClick={() => changeLanguage("ar")}
                  className="btn Tajawal"
                >
                  عربى
                </button>
              )}
            </li>
          )}
          <li>
            <NavLink
              to="about"
              className="text-decoration-none tajawal"
              style={{ color: "var(--main-color)" }}
            >
              {t("aboutUs")}
            </NavLink>
          </li>
        </ul>
        {/* <Outlet /> */}
      </div>
    </nav>
  );
};

export default Navbar;
