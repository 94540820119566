import { Link, NavLink, Navigate, Outlet } from "react-router-dom";
import { MdSpaceDashboard } from "react-icons/md";
import { PiChartLineUpLight } from "react-icons/pi";
import { HiOutlineChartBar } from "react-icons/hi2";
import { GrLogout } from "react-icons/gr";
import logo from "../../components/assets/logo.png";
import { useContext } from "react";
import FormDataContext from "../../FormDataContext";
import { useAuth } from "../../AuthContext";
import Cookies from "js-cookie";

const ProtectedRoutes = () => {
  const { allCases } = useContext(FormDataContext);
  const { isAuthorized, logout } = useAuth();

  return Cookies.get("isAuthorized") === "true" ? (
    <div className="container p-2 mb-3 " dir="ltr">
      <div className="row">
        <div className="col-2">
          <br />
          <div>
            <NavLink to="/">
              <img
                src={logo}
                width={165}
                height={78}
                className="img-fluid"
                alt="Speak up logo"
              />
            </NavLink>
          </div>
          <br />
          <br />
          <br />
          <div className="d-flex justify-content-center flex-column align-items-start px-5 text-start mt-3">
            <Link className="mb-3 text-decoration-none" to="cases">
              <h6 className="primary-color d-flex flex-row justify-content-center align-content-center gap-1">
                <span className="d-flex justify-content-lg-center align-items-center">
                  <HiOutlineChartBar />
                </span>
                Cases <span className=" p-">({allCases?.length})</span>
              </h6>
            </Link>
            <Link className="mb-3 text-decoration-none" to="dashboard">
              <h6 className="primary-color d-flex flex-row justify-content-center align-items-center gap-1">
                <span className="d-flex justify-content-lg-center align-items-center">
                  <MdSpaceDashboard />
                </span>
                <span>Dashboard</span>
              </h6>
            </Link>
            <Link className="mb-3 text-decoration-none" to="analysis">
              <h6 className="primary-color d-flex flex-row justify-content-center align-content-center gap-1">
                <span className="d-flex justify-content-lg-center align-items-center">
                  <PiChartLineUpLight />
                </span>
                Analysis
              </h6>
            </Link>
          </div>
          <br />
          <br />

          <hr />
          <div>
            <Link className="mb-3 text-decoration-none" to="/login">
              <h6
                onClick={() => logout()}
                className="primary-color d-flex flex-row justify-content-center align-content-center gap-1"
              >
                <span className="primary-color">
                  <GrLogout />
                </span>
                Log out
              </h6>
            </Link>
          </div>
        </div>
        <div className="col-10 " style={{ backgroundColor: "#F1F2F7" }}>
          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoutes;
