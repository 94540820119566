import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    company: "",
    classification: "",
    message: "",
    file: null,
    record: null,
  });
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  const [uniqueNumber, setUniqueNumber] = useState(null);
  const [userReportDetails, setUserReportDetails] = useState({});
  const [allCases, setAllCases] = useState([]);

  const getAllCases = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("data_type", "getAllCases");
    try {
      const res = await axios.post(
        "https://speakup.mountainviewegypt.com/__API/index.php",
        formDataToSend
      );

      setAllCases(res?.data?.cases);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllCases();
    // to update the number of cases each time a new case is added in realtime without refreshing the page
    // 5 Minutes
    const intervalId = setInterval(getAllCases, 300000);
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const updateFormData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };
  const updateTrackNo = (newData) => {
    setUniqueNumber(newData);
  };
  const updateLoginFormData = (newData) => {
    setLoginFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <FormDataContext.Provider
      value={{
        formData,
        loginFormData,
        allCases,
        updateFormData,
        updateLoginFormData,
        uniqueNumber,
        setUniqueNumber,
        userReportDetails,
        setUserReportDetails,
        updateTrackNo,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
};

export default FormDataContext;
